import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/index',
    //   name: 'home',
    //   component: HomeView
    // },
   
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // },


    {
      path: '/',
      name: 'index',
      component: ()=>import('@/components/indexVue.vue'),
      children:[

        {
          path: '/home',
          name: 'home',
          component: ()=>import('@/components/HomeVue.vue')
        },
        {
          path: '/Leaderboard',
          name: 'Leaderboard',
          component: ()=>import('@/components/LeaderboardVue.vue')
        },
        {
          path: '/Friends',
          name: 'Friends',
          component: ()=>import('@/components/FriendsVue.vue')
        },
      ]
    },
    {
      path: '/error',
      name: 'error',
      component: ()=>import('@/components/ErrorVue.vue'),
    }




  ]
})




export default router
