import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Cookies from 'js-cookie';
import axios from "axios";

const app = createApp(App);
axios.defaults.baseURL="https://etfvip.pro/api"
// axios.defaults.baseURL="http://localhost:8082/api"

app.config.globalProperties.$cookies = Cookies;


app.use(router).use(ElementPlus).mount('#app')
